const ContractTypeTag = ({ contractType }) => {


    return (
        <>
                <div><h6 className="typeTag">{contractType}</h6></div>
        </>
    )
}

export default ContractTypeTag